import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router";
import TotemTable from "../components/TotemTable";
import ProductsTable from "../components/ProductsTable";
import SurveyTable from "../components/SurveyTable";
import ResultsTable from "../components/ResultsTable";
import ReportsTable from "../components/ReportsTable";
import { jwtDecode } from "jwt-decode";

const Admin = () => {
  const navigate = useNavigate();
  const [activeComponent, setActiveComponent] = useState(() => {
    return localStorage.getItem("activeComponent") || "totems";
  });

  const [userInfo, setUserInfo] = useState(null);
  const [userRole, setUserRole] = useState('');

  const handleSidebarItemClick = (component) => {
    setActiveComponent(component);
  };

  const sidebarOptions = [
    { id: "totems", label: "Totems", icon: "/assets/svg/totem.svg" },
    { id: "products", label: "Prodotti", icon: "/assets/svg/prodotti.svg" },
    { id: "surveys", label: "Surveys", icon: "/assets/svg/survey.svg" },
    { id: "results", label: "Risultati", icon: "/assets/svg/risultati.svg" },
    // { id: "reports", label: "Reports", icon: "/assets/svg/reports.svg" },
  ];


  useEffect(() => {

    switch (userInfo?.role) {
      case 'superAdmin':
        setUserRole('Amministratore generale')
        break;
      case 'admin':
        setUserRole('Amministratore locale')
        break;
      default:
        setUserRole('Utente comune')
        break;
    }

  }, [userInfo])

  const renderComponent = () => {
    const selectedOption = sidebarOptions.find(
      (option) => option.id === activeComponent
    );

    if (selectedOption) {
      if (selectedOption.id === "totems") {
        return <TotemTable />;
      } else if (selectedOption.id === "products") {
        return <ProductsTable />;
      } else if (selectedOption.id === "surveys") {
        return <SurveyTable />;
      } else if (selectedOption.id === "results") {
        return <ResultsTable />;
      } else if (selectedOption.id === "reports") {
        return <ReportsTable />;
      }
    }

    return null;
  };

  useEffect(() => {
    const token = localStorage?.getItem("TotemVitaMatchTOKEN");

    if (token === 'undefined' || token === null) {
      navigate("/login");
    } else {
      try {
        const decodedToken = jwtDecode(token);
        setUserInfo(decodedToken);

        console.log("USER ---> ", decodedToken);

      } catch (error) {
        console.error("Errore nella decodifica del token:", error);
        navigate("/login");
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    localStorage.setItem("activeComponent", activeComponent);
  }, [activeComponent]);

  useEffect(() => {
    if (!localStorage.getItem("TotemVitaMatchTOKEN")) {
      navigate("/");
    }
  }, [localStorage]);

  const handleLogout = () => {
    localStorage.removeItem("TotemVitaMatchTOKEN");
    navigate("/");
  };

  return (
    <>
      <div className="admin">
        <div className="navbar bg-color px-5">
          <div className="logo ">
            <img src="/assets/images/logo/vitamatch.png" alt="" />
          </div>

          {userInfo && (
            <div className="logout-link">
              <span className="text-capitalize">{userRole}</span>
            </div>
          )}

          <button
            type="button"
            className="logout-link"
            onClick={handleLogout}>
            <img className="logout-svg" src="/assets/svg/logout.svg" /> <span>Esci</span>
          </button>
        </div>

        <div className="dashboard">
          {/* Sidebar Menu */}
          <div className="sidebar bg-color">
            <div className="top-sidebar">
              <h4 className="px-3">Menu</h4>
              <ul className="nav flex-column mt-5">
                {sidebarOptions.map((option) => (
                  <li
                    className={`nav-item ${option.id === activeComponent ? "active" : ""
                      }`}
                    key={option.id}>
                    <a
                      className="nav-link"
                      href={`#${option.id}`}
                      onClick={() => handleSidebarItemClick(option.id)}>
                      <img src={option.icon} alt={option.label} />
                      <span>{option.label}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="main-content" id={activeComponent}>
            {renderComponent()}
          </div>
        </div>
      </div>

      <Toaster position="top-left" reverseOrder={false} />
    </>
  );
};

export default Admin;
