import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import SignUp from "./pages/SignUp";
import Survey from "./pages/Survey";
import LoginAdmin from "./pages/LoginAdmin";
import Admin from "./pages/Admin";
import Page404 from "./pages/Page404";
import Recommendations from "./pages/Recommendations";
import Congratulations from "./pages/Congratulations";
import { LanguageProvider } from "./services/functions/LanguageProvider";
import Credits from "./pages/Credits";

function App() {
  return (
    <div className="App">
      <LanguageProvider>
      <Router basename="/">
        <Layout>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/credits" element={<Credits />} />
            <Route exact path="/survey" element={<Survey />} />
            <Route exact path="/login-admin" element={<LoginAdmin />} />
            <Route exact path="/admin" element={<Admin />} />
            <Route exact path="/end-of-survey" element={<Congratulations />} />
            <Route exact path="/recommendations" element={<Recommendations />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Layout>
      </Router>
      </LanguageProvider>
    </div>
  );
}

export default App;
