import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const Layout = ({ children }) => {
  const location = useLocation();

  //GOOGLE
  useEffect(() => {
    ReactGA.initialize("G-PM8JJVCGKX");
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <>
      {children}
    </>
  );
};

export default Layout;
